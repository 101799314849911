
import { defineComponent, getCurrentInstance, ref, watch } from "vue";

interface IHeaderConfiguration {
  name?: string;
  key: string;
  sortingField?: string;
  sortable?: boolean;
}

interface IPagination {
  total: number;
  pageNumber: number;
  pageSize: number;
}

export default defineComponent({
  name: "sea-datatable",
  emit: ["page-number-change", "page-size-change", "sort"],
  props: {
    loading: { type: Boolean, default: false },
    tableHeaders: {
      type: Array as () => Array<IHeaderConfiguration>,
      required: true,
    },
    data: { type: Array, required: true, default: null },
    total: { type: Number, default: 0 },
    pageNumber: { type: Number, default: 0 },
    pageSize: { type: Number, default: 0 },
    order: { type: String },
    emptyTableText: { type: String, default: "No data found" },
    enablePageSizeDropdown: { type: Boolean, default: true },
  },
  setup(props, { emit }) {
    const currentSort = ref<string>("");
    const pagination = ref<IPagination>({
      total: props.total,
      pageNumber: props.pageNumber,
      pageSize: props.pageSize,
    });
    const order = ref(props.order);

    const vNodeProps = getCurrentInstance()?.vnode.props || {};

    watch(
      () => props.total,
      () => {
        pagination.value.total = props.total;
      }
    );

    watch(
      () => props.pageNumber,
      () => {
        pagination.value.pageNumber = props.pageNumber;
      }
    );

    watch(
      () => props.pageSize,
      () => {
        pagination.value.pageSize = props.pageSize;
      }
    );

    const setPageNumber = (val) => {
      if ("onPageNumberChange" in vNodeProps) {
        emit("page-number-change", val);
      }
    };

    const setPageSize = (event) => {
      if ("onPageSizeChange" in vNodeProps) {
        emit("page-size-change", parseInt(event.target.value));
      }
    };

    const sort = (columnName, sortable) => {
      if (sortable !== true) {
        return;
      }

      if ("onSort" in vNodeProps) {
        if (order.value === "asc") {
          order.value = "desc";
          emit("sort", { columnName: columnName, order: "desc" });
        } else {
          order.value = "asc";
          emit("sort", { columnName: columnName, order: "asc" });
        }
      }

      currentSort.value = columnName + order.value;
    };

    return {
      currentSort,
      pagination,
      setPageNumber,
      setPageSize,
      sort,
    };
  },
});
